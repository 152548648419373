import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { BoxArrowInRight, CardList, FileText, Grid3x3GapFill, FileEarmarkText, FileEarmarkPdf, Download } from 'react-bootstrap-icons';
import "../css/main.css";

const About = () => {
    const [currentCount, setCurrentCount] = useState(0);

    const incrementCounter = () => {
        setCurrentCount(currentCount + 1);
    };

    return (
        <Container fluid>
            <h1 className="justify-content-center text-center">Contact US</h1>
            <h3 className="justify-content-center text-center">
                We are currently situated in South Africa and the United Kingdom.<br />
                If you need to contact us, please feel free to contact either one of our offices.
            </h3>

            <div>
                <Container>
                    <Row>
                        <Col>
                            <section id="SA" style={{ height: '36vh' }}>
                                <div className="contactblocks justify-content-center text-center">
                                    <div className="contact_Header">
                                        <h1 id="cH1">South Africa</h1>
                                        <p>
                                            <span className="glyphicon glyphicon-home"></span> 49 Alexander Road, Westmead, Pinetown, Kwazulu-Natal, South Africa, 3608
                                        </p>
                                        <p>
                                            <i className="glyphicon glyphicon-phone-alt"></i> Tel: +27 31 780 2560
                                        </p>
                                        <p>
                                            <i className="glyphicon glyphicon-envelope"></i>
                                            <a href="mailto:sales%40mhe.co.za"> Email: sales@mhe.co.za</a>
                                        </p>
                                        <iframe
                                            id="cmap1"
                                            style={{ width: '100%', height: '360px', border: '0' }}
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1041.7150882390254!2d30.83542771808612!3d-29.821198313456204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef6fe93b4bf4001%3A0xfd7d416f1cf1f608!2s49+Alexander+Rd%2C+Westmead%2C+Pinetown%2C+3608!5e0!3m2!1sen!2sza!4v1566297880331!5m2!1sen!2sza"
                                            className="MapSize"
                                            frameBorder="0"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </section>
                        </Col>
                        <Col>
                            <section id="UK" style={{ height: '36vh' }}>
                                <div className="contactblocks justify-content-center text-center">
                                    <div className="contactblocks">
                                        <div className="contact_Header">
                                            <h1 id="cH1">United Kingdom</h1>
                                            <p>
                                                <span className="glyphicon glyphicon-home"></span> Fairstead House, Erratts Hill, Cowlinge, Newmarket, Suffolk, CB8 9QD
                                            </p>
                                            <p>
                                                <i className="glyphicon glyphicon-phone-alt"></i> Tel: +44 1440 820381
                                            </p>
                                            <p>
                                                <i className="glyphicon glyphicon-envelope"></i>
                                                <a href="mailto:mike%40mheuk.co.uk"> Email: mike@mheuk.co.uk</a>
                                            </p>
                                            <iframe
                                                id="cmap2"
                                                style={{ width: '100%', height: '360px', border: '0' }}
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.8502804225955!2d0.2824975157300256!3d52.463738879803564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8114914b7d251%3A0x41c753515ccf87e2!2sHenry+Crabb+Rd%2C+Littleport%2C+Ely+CB6+1SE%2C+UK!5e0!3m2!1sen!2sza!4v1510753875853"
                                                className="MapSize"
                                                frameBorder="0"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Container>
    );
};

export default About;
