import React, { useState } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../assets/mhe_logo.png';

const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <Navbar className="navbar-dark bg-black">
            <NavbarBrand href="/">
                <img src={logo} alt="Logo" className="logo" />
            </NavbarBrand>
            <Nav className="mx-auto nav-links">
                <NavItem>
                    <NavLink href="/" className="nav-link">Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/Products" className="nav-link text-center">Products</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/about" className="nav-link">About Us</NavLink>
                </NavItem>
            </Nav>
            <span style={{ width: '6%' }}></span>
        </Navbar>
    );
};

export default NavMenu;
