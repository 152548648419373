import React, { useState, useEffect } from 'react';
import HomeImage from '../assets/homeImage.jpg';
import '../css/main.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {
    const [imageDataList, setImageDataList] = useState([]);
    const [products, setProducts] = useState([]);
    const [content, setContent] = useState([]);
    const [imageDirectory, setImageDirectory] = useState('./MHEWebsiteAssets');
    const bearerToken = localStorage.getItem('bearerToken');
    const navigate = useNavigate();

    useEffect(() => {
        const authenticateAndCheckImages = async () => {
            try {
                await authUser();
                await runImageCheck();
            } catch (error) {
                console.error('Error during authentication or image check:', error);
            }
        };
        getProductsList();
        //getImageDirectory();
        authenticateAndCheckImages();
    }, []);

    const authUser = async () => {
        try {
            const response = await axios.get("/Website/MHEWebsiteLogin", {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
            console.log('Auth response:', response.data);
        } catch (error) {
            console.error('Authentication error:', error);
        }
    };

    const getProductsList = async () => {
        try {
            const response = await axios.get('/Website/getProducts', {
                headers: { Authorization: `Bearer ${bearerToken}` },
            });
            setProducts(response.data);
            setContent(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching product list:', error);
        }
    };

    const runImageCheck = async () => {
        try {
            const response = await axios.get("/Website/CheckAndCopyBlobStorageToWebsite", {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
            console.log('Image check response:', response.data);
        } catch (error) {
            console.error('Image check error:', error);
        }
    };

    const getImageDirectory = async () => {
        try {
            const response = await axios.get("/Website/GetImageDirectory", {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
            setImageDirectory(response.data);
            return response.data;


        } catch (error) {
            console.error(error);
        }
    };
   
    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 3;

    const handleNext = () => {
        if (startIndex + itemsPerPage < products.length) {
            setStartIndex(startIndex + 1);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1);
        }
    };

    const featureNav = {
        color: 'blue'
    }

    const genImagePath = (imgPath) => {

        return + imageDirectory + '/' + imgPath;
    }

    return (
        <div className="fullscreen-image">
            <img src={HomeImage} alt="Home"  />
       
            <div className="featured-products">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                    }}
                >
                    <h1 style={{ marginLeft: "10%" }}>Featured Products</h1>
                    <div style={{ marginRight: "10%" }}>
                        <button className="arrow left" onClick={handlePrev}>
                            &#8592;
                        </button>
                        <button className="arrow right" onClick={handleNext}>
                            &#8594;
                        </button>
                    </div>
                </div>

                <div className="carousel">
                    <div className="product-container">
                        {products
                            .sort((a, b) => {
                                const aHasNX = a.proD_NAME.includes("NX") ? 1 : 0;
                                const bHasNX = b.proD_NAME.includes("NX") ? 1 : 0;
                                return bHasNX - aHasNX;
                            })
                            .slice(startIndex, startIndex + itemsPerPage)
                            .map((product) => (
                                <Link
                                    key={product.id}
                                    className="product-link"
                                    to={`/ProdView/${product.proD_NAME}`}
                                >
                                    <div className="product">
                                        <img
                                            src={`${imageDirectory}/${product.imG_PATH}`}
                                            alt={product.proD_NAME}
                                        />
                                        <h2>{product.proD_NAME}</h2>
                                    </div>
                                </Link>
                            ))}
                    </div>
                </div>

                <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <button className="all-products-button" onClick={() => { navigate(`/Products`); }}>All Products</button>
                </div>
            </div>
        </div>
    );
};

export default Home;
