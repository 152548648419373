import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import { CardList, FileText, Grid3x3GapFill, FileEarmarkText, FileEarmarkPdf, Download } from 'react-bootstrap-icons';
import "../css/main.css";
import { useParams } from 'react-router-dom';

const ProdView = () => {
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [content, setContent] = useState(null);
    const [selectedButton, setSelectedButton] = useState('product');
    const [features, setFeatures] = useState(null);
    const [documents, setDocuments] = useState(null);
    const [gallery, setGallery] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageDirectory, setImageDirectory] = useState('./MHEWebsiteAssets');

    const { productName } = useParams();


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await getProductData();
          
        };
        fetchData();
    }, []);

    const getProductData = async () => {
        try {
            console.log(productName);
            const response = await axios.post(
                "/Website/GetProductByName",
                { ProdName: productName }, // Send productName inside an object
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                    },
                }
            );

            setProduct(response.data);
           // await getImageDirectory();
            await getProductFeatures(response.data);
            await getProductDocumentation(response.data);
            await getGalleryFiles(response.data);
            await renderProduct(response.data);
           
        } catch (error) {
            console.error(error);
        }
    };

    const getImageDirectory = async () => {
        try {
            const response = await axios.get("/Website/GetImageDirectory", {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
            setImageDirectory(response.data);
            return response.data;
            
           
        } catch (error) {
            console.error(error);
            setIsLoading(true);
        }
    };

    const getGalleryFiles = async (prod) => {
        try {
            const response = await axios.post("/Website/GetGalleryImagesByPath", prod.proD_ID, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
            setGallery(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getProductFeatures = async (prod) => {
        try {
            const response = await axios.post("/Website/GetDescriptionByProdID", prod.proD_ID, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
            const arr = JSON.parse(response.data.desC_JSON);
            setFeatures(arr.list);
        } catch (error) {
            console.error(error);
        }
    };

    const getProductDocumentation = async (prod) => {
        try {
            const response = await axios.post("/Website/GetDocumentsbyProdID", prod.proD_ID, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
            setDocuments(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const renderProduct = (prod) => {

        var Directory = getImageDirectory()

        const productContent = (
            <>
                <Col sm={6} className="text-center">
                    <div className="image-container-ProdView">
                        <img
                            src={`${imageDirectory}/${prod.imG_PATH}`}
                            alt="Product"
                            className="product-image-ProdView"
                        />
                    </div>
                </Col>
                <Col>
                    <div className="product-description-container-floating fade-in text-center">
                        <div className="product-description">{prod.proD_DESC}</div>
                    </div>
                </Col>
            </>
        );
        setIsLoading(false);
        setContent(productContent);
    };

    const renderFeatures = (prod) => {
        const featuresContent = (
            <>
                <Col sm={6}>
                    <div className="product-description-container fade-in">
                        <h3>Features:</h3>
                        <ul className="bullet-list">
                            {features.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </Col>
                <Col className="text-center">
                    <div className="image-container-ProdView">
                        <img
                            src={`${imageDirectory}/${prod.imG_PATH}`}
                            alt="Product"
                            className="product-image-ProdView"
                        />
                    </div>
                </Col>
            </>
        );
        setContent(featuresContent);
    };

    const renderDocuments = (prod) => {
        const style = {
            fontSize: '20px',
            marginTop: '10%'
        }
        const documentsContent = (<>
            <Col sm={6} style={style} >
                <h3>Documents</h3>
                {documents.map((document, index) => {
                    let icon = <FileEarmarkText />;
                    if (document.doC_PATH.includes("pdf")) {
                        icon = <FileEarmarkPdf />;
                    } else if (document.doC_PATH.includes("exe")) {
                        return (
                            <div key={index}>
                                <a
                                    href={imageDirectory + '/' + document.doC_PATH}
                                    download
                                    style={{ color: 'black', display: 'block', marginBottom: '10px' }}
                                >
                                    <Download />   {document.doC_NAME}

                                </a>
                            </div>
                        );
                    }
                    return (
                        <div key={index}>
                            <a
                                href={imageDirectory + '/' + document.doC_PATH}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'black', display: 'block', marginBottom: '10px' }}
                            >
                                {icon} {document.doC_NAME}
                            </a>
                        </div>
                    );
                })}
            </Col>
            <Col className="text-center">
                <div className="image-container-ProdView">
                    <img
                        src={`${imageDirectory}/${prod.imG_PATH}`}
                        alt="Product"
                        className="product-image-ProdView"
                    />
                </div>
            </Col>
        </>
        );
        setContent(documentsContent);
    }

    const renderGallery = () => {
        const galleryContent = (
            <Col sm={6} className="text-center">
                <h3>Gallery</h3>
                <div className="image-container-ProdView-gallery image-grid">
                    {gallery.map((image, index) => (
                        <div
                            key={index}
                            className="image-item-gallery-prod"
                            onClick={() => toggleModal(image.imG_PATH, index)}
                        >
                            <img
                                src={`${imageDirectory}/${image.imG_PATH}`}
                                alt="Product"
                                className="product-image-ProdView-gallery"
                            />
                        </div>
                    ))}
                </div>
            </Col>
        );
        var Gallery = (<>
            <Col sm={6} className="text-center">
                <h3>Gallery</h3>
                <div className="image-container-ProdView-gallery image-grid">
                    {gallery.map((image, index) => (
                        <div key={index} className="image-item-gallery-prod" onClick={() => toggleModal(image.imG_PATH, index)}>
                            <img
                                src={`${imageDirectory}/${image.imG_PATH}`}
                                alt="Product"
                                className="product-image-ProdView-gallery"

                            />
                        </div>
                    ))}
                </div>
            </Col>
            <Col >

            </Col>
        </>)

        setContent(Gallery);
    };

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        if (buttonName === 'features') renderFeatures(product);
        if (buttonName === 'product') renderProduct(product);
        if (buttonName === 'documents') renderDocuments(product);
        if (buttonName === 'gallery') renderGallery();
    };

    const toggleModal = (image, index) => {
        setIsOpen(!isOpen);
        setSelectedImage(image);
        setCurrentIndex(index);
    };

   const closeModal = () => {
       setIsOpen(false);
       setSelectedImage(null);
       setCurrentIndex(0);
    };

    const goToPrevious = () => {
        setCurrentIndex(prevIndex => prevIndex - 1);
    };

    const goToNext = () => {
        setCurrentIndex(prevIndex => prevIndex + 1);
    };

    return (
        <Container fluid>
            <h2 className="justify-content-center text-center">{productName}</h2>
            <Row className="justify-content-center">
                <Col>
                    <div className="button-section d-flex align-items-center justify-content-center" style={{ marginTop: '25%' }}>
                        <div className="button-container prodView-button-list d-flex">
                            <div className="circle-button" onClick={() => handleButtonClick('product')}>
                                <div className={selectedButton === 'product' ? 'icon-wrapper menu-btn-selected' : 'icon-wrapper menu-btn-unselected'}>
                                    <Grid3x3GapFill className="button-icon" />
                                </div>
                                <div className="button-text">Product</div>
                            </div>
                            <div className="circle-button" onClick={() => handleButtonClick('features')}>
                                <div className={selectedButton === 'features' ? 'icon-wrapper menu-btn-selected' : 'icon-wrapper menu-btn-unselected'}>
                                    <CardList className="button-icon" />
                                </div>
                                <div className="button-text">Features</div>
                            </div>
                            <div className="circle-button" onClick={() => handleButtonClick('documents')}>
                                <div className={selectedButton === 'documents' ? 'icon-wrapper menu-btn-selected' : 'icon-wrapper menu-btn-unselected'}>
                                    <FileText className="button-icon" />
                                </div>
                                <div className="button-text">Documents</div>
                            </div>
                            <div className="circle-button" onClick={() => handleButtonClick('gallery')}>
                                <div className={selectedButton === 'gallery' ? 'icon-wrapper menu-btn-selected' : 'icon-wrapper menu-btn-unselected'}>
                                    <FileText className="button-icon" />
                                </div>
                                <div className="button-text">Gallery</div>
                            </div>
                        </div>
                    </div>
                </Col>
                {isLoading ? (
                    <Col sm={6} className="text-center" style={{ paddingTop: '10%' }}>
                        <span className="loader"></span>
                    </Col>
                ) : (
                    content
                )}
            </Row>
            <Modal isOpen={isOpen} toggle={closeModal} size="xl">

                <ModalBody className="modal-body">
                    <div className="image-container-prodView-modal">
                        {selectedImage && (
                            <img
                                src={imageDirectory + '/' + selectedImage}
                                alt="Product"
                                className="full-screen-image-prodView-modal"
                            />
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </Container>
    );
};

export default ProdView;
